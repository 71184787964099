.row .box {
  line-height: 1.4;
}

.page-customers .row .box .box-content {
  text-align: justify;
  margin-top: -45px;
}

.page-team .row .box .box-content {
  text-align: justify;
}

table.pricing {
  width: 450px;
  margin: auto;

  th {
    text-align: left;
    font-weight: bold;
    width: 50%;
    background: #ccc;
    color: #000;
  }

  th, td {
    padding: 3px 4px;
    text-align: center;
  }
}

#nav {
  ul > li {
    font-style: normal;

    a {
      font-weight: normal;
    }

    &.current > a {
      font-weight: normal;
    }
  }
}
